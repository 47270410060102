<template>
  <div class="kepzesek container">
    <h1>&nbsp;</h1>
    <div class="row">
      <div class="col-12">
        <div>
          <form v-on:submit.prevent="jelentkezoTarol" class="">
            <h1 class="kek" style="text-align: center">Tájékoztató</h1>
            <h3 class="kek">Üdvözöljük!</h3>
            <h4 class="kek">Első lépésként kérjük tekintse át tervezett képzési kínálatunkat a lenyíló lista segítségével.</h4>
            <h4 class="kek">A tervezett képzéseink listájából itt tud választani!</h4>
            <img src="../assets/images/lmpny.jpg" width="50px">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <div class="form-floating mb-3">
                  <select
                    id="eloKepzesId"
                    class="form-select"
                    placeholder="EloKepzesId"
                    required
                    v-model="form.eloKepzesId"
                  >
                    <option
                      v-for="elokepzes in elokepzesek"
                      v-bind:key="elokepzes.id"
                      :value="elokepzes.id"
                    >
                      {{ elokepzes.kepzesNev }}
                    </option>
                  </select>
                  <label for="eloKepzesId">Képzés neve - indítás várható időpontja*</label>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="nev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Nev"
                    required
                    v-model="form.nev"
                  />
                  <label for="knev">Név*</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="email"
                    type="email"
                    class="form-control rounded-3"
                    placeholder="Email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                    required
                    v-model="form.email"
                  />
                  <label for="email">E-mail cím*</label>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="telefon"
                    type="tel"
                    class="form-control rounded-3"
                    placeholder="+36301234567"
                    pattern="((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})"
                    required
                    v-model="form.telefonszam"
                  />
                  <label for="telefon"
                    >Telefonszám* (+36-xx-xxx-xxxx v. +36-xx-xxx-xxx)</label
                  >
                </div>
              </div>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="informacio"
                @click="informacioKapcsolas()"
              />
              <label class="form-check-label" for="informacio">
                Feliratkozom a Miskolci Szakképzési Centrum hírlevelére, továbbá
                hozzájárulok, hogy részemre a Miskolci Szakképzési Centrum a
                felnőttképzésről szóló legfrissebb információkat küldjön.
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="adatvedelem"
                @click="gombKapcsolas()"
              />
              <label class="form-check-label" for="adatvedelem">
                Jelen adatlap kitöltésével és beküldésével kijelentem, hogy az
                <a
                  href="https://www.miskolci-szc.hu/uploads/documents/45_2020--sz--kancellari-es-foig-utasotas-adatvedelmi-szabalyzat.pdf"
                  target="_blank"
                  >Adatkezelési tájékoztatót</a
                >
                elolvastam, az abban foglaltakat tudomásul vettem, illetve
                nyilatkozom, hogy a megadott adatok a valóságnak megfelelnek.
              </label>
            </div>
            <p></p>
            <button
              class="w-100 mb-2 btn btn-lg rounded-3 btn-primary"
              id="jelentkezesGomb"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-people-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
                />
              </svg>
              Jelentkezés
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <br />
    <div id="footer" class="container-fluid">
      <br />
      <div class="row bb-1">
        <div class="col-sm-12">
          <img src="../assets/images/footer-logo.png" class="logo-bottom" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-sm-12">
          <div class="bottom-menu-2 src-ligth text-center">
            <a
              href="https://www.miskolci-szc.hu/oldal/kozerdeku-adatok"
              target="_blank"
              >Közérdekű adatok</a
            >
            <span class="elvalasztoVonalSzine"> | </span>
            <a
              href="https://www.miskolci-szc.hu/uploads/documents/45_2020--sz--kancellari-es-foig-utasotas-adatvedelmi-szabalyzat.pdf"
              target="_blank"
              >Adatkezelési irányelvek</a
            >
            <p>&nbsp;</p>
            <p>
              Minden jog fenntartva: Miskolci Szakképzési Centrum 3525 Miskolc,
              Széchenyi István utca 103. Tel: 46/796-020 E-mail:
              titkarsag@miskolci-szc.hu
            </p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      aktDatum: new Date(),
      elokepzesek: [],
      elokepzesekSzotar: {},
      jelentkezoId: 0,
      kepzesnev: "",
      kepzesNeve: "",
      kepzesid: 0,
      kepzoIntId: 0,
      kepzesiElofeltetel: "",
      form: {
        id: 0,
        eloKepzesId: "",
        nev: "",
        email: "",
        telefonszam: "",
        jelentkezesDatuma: new Date(),
        informacio: false,
      },
    };
  },
  methods: {
    refreshData() {
      this.form = this.uresForm;
    },
    async BackendSearch() {
      let talaltam = false;
      const urlk = process.env.VUE_APP_URL.split(",");
      for (const index in urlk) {
        if (!talaltam) {
          let path = urlk[index] + "Kepzesek";
          await axios
            .get(path)
            .then((response) => {
              if (response.status == 200) {
                this.$store.state.API_URL = urlk[index];
                talaltam = true;
                //console.log(this.$store.state.API_URL);
                this.elokepzesekBeolvasasa();
              }
            })
            .catch((error) => {
              console.log("");
            });
        }
      }
    },
    informacioKapcsolas() {
      let cb = document.getElementById("informacio");
      if (cb.checked) {
        this.form.informacio = true;
      } else {
        this.form.informacio = false;
      }
      //console.log(this.jelentkezo.informacio);
    },
    gombKapcsolas() {
      let cb = document.getElementById("adatvedelem");
      let jg = document.getElementById("jelentkezesGomb");
      if (cb.checked) {
        jg.disabled = false;
      } else {
        jg.disabled = true;
      }
    },
    jelentkezoTarol() {
      let jg = document.getElementById("jelentkezesGomb");
      jg.textContent = "Jelentkezés feldolgozása...";
      jg.disabled = true;
      let url =
        this.$store.state.API_URL + "Elojelentkezok/" + this.$store.state.key;
      axios
        .post(url, this.form)
        .then((response) => {
          alert("Kedves " + response.data + "! Köszönjük jelentkezését!");
          const closeTab = () => window.close(``, `_parent`, ``);
            closeTab();
        })
        .catch((error) => {
          alert(error);
        });
      this.form.eloKepzesId = 0;
      this.form.nev = "";
      this.form.email = "";
      this.form.telefonszam = "";
      this.form.informacio = false;
      let cba = document.getElementById("adatvedelem");
      cba.checked=false;
      let cbi = document.getElementById("informacio");
      cbi.checked=false;
    },
    async elokepzesekBeolvasasa() {
      let url = this.$store.state.API_URL + "elokepzesek/";
      await axios
        .get(url)
        .then((response) => {
          this.elokepzesek = response.data;
          for (let i = 0; i < this.elokepzesek.length; i++) {
            this.elokepzesekSzotar[this.elokepzesek[i].kepzesNev] =
              this.elokepzesek[i].id;
          }
        })
        .catch((error) => {
          this.elojelentkezokSzama = -1;
        });
    },
  },
  mounted: function () {
    this.BackendSearch();
  },
};
</script>

<style scoped>
.kek{
  color: #339fd7;
  font-weight: bold;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #e9802b !important;
}
@media screen and (min-width: 1200px) {
  div#divUnder1200px {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  div#divAbove1200px {
    display: none;
  }
}
h3,
h4 {
  text-align: left;
  font-family: SourceSansPro-Light;
}
h3 {
  font-weight: bold;
  margin-top: 20px;
}
.icon {
  color: rgb(249, 5, 70);
  font-size: 70px;
}
button {
  margin-top: 10px;
  font-family: SourceSansPro-Light;
}

.form-check,
.form-check-label {
  margin-top: 10px;
  font-family: SourceSansPro-Bold;
}

#footer {
  background-color: #339fd7;
  font-family: SourceSansPro-Light;
  font-size: 18px;
}

.bottom-menu-2,
.bottom-menu-2 a {
  color: #fff;
  font-family: SourceSansPro-Light;
  line-height: 26px;
  display: inline-block;
}
.bottom-menu-2 p {
  margin: 0;
}

input {
  font-family: SourceSansPro-SemiBold;
}

label,
textarea {
  font-family: SourceSansPro-Semiold;
}
a {
  text-decoration: none;
}
.elvalasztoVonalSzine {
  color: black;
}
p {
  margin-top: -12px !important;
}
img {
  margin-bottom: -20px;
}
</style>
