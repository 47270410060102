import { createStore } from "vuex";

export default createStore({
  state: {
    API_URL: "",
    key: "1d93f8a843855a0bf41d68584b51bba33b1361a760d5c3fdd2f2578b9023f2cc",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
